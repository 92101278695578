import MuiSlider from '@mui/material/Slider'

const Slider = (props: any) => (
  <MuiSlider
    {...props}
    track={false}
    sx={{
      '& .MuiSlider-rail': {
        backgroundColor: '#D9D9D9',
        height: '6px'
      },
      '& .MuiSlider-mark': {
        backgroundColor: '#fff',
        border: '2px solid #D9D9D9',
        borderRadius: '50%',
        transform: 'translate(-8px, -50%)',
        height: '16px',
        width: '16px',
        opacity: 1
      },
      '& .MuiSlider-thumb': {
        backgroundColor: '#E87A3F',
        height: '16px',
        width: '16px',
        '&::before, &.Mui-active': {
          boxShadow: 'none'
        },
        '&::after': {
          display: 'none'
        }
      }
    }}
  />
)

export default Slider