import React, { Suspense, useEffect, useRef } from 'react'
import { Vector3, Box3, Group, Mesh, Object3D, Color, MeshStandardMaterial } from 'three'
import { Canvas, useLoader } from '@react-three/fiber';
import { Float } from "@react-three/drei/core";
import { OrbitControls, Stage } from "@react-three/drei";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

import Loading from './Loading';

type Props = {
  fileUrl: string;
}

const OBJModel = ({ fileUrl }: Props) => {
  const groupRef = useRef<Group | null>(null);
  const obj = useLoader(OBJLoader, fileUrl)
  useEffect(() => {
    if (groupRef.current) {
      const box = new Box3().setFromObject(obj);
      const center = new Vector3();
      box.getCenter(center);
      obj.position.sub(center);
      const customMaterial = new MeshStandardMaterial({ color: new Color(0x94D5D7) });
      obj.traverse((child: Object3D) => {
        if (child instanceof Mesh) {
          child.material = customMaterial;
        }
      });
      groupRef.current.add(obj);
    }
  }, [obj]);

  return (
    <mesh scale={1}>
      <group ref={groupRef} />
    </mesh>
  )
}


const STLModel = ({ fileUrl }: Props) => {
  const stl = useLoader(STLLoader, fileUrl);
  return (
    <mesh scale={1}>
      <primitive object={stl} attach="geometry" />
      <meshStandardMaterial color="#94D5D7" />
    </mesh>
  )
}

const Model = ({ fileUrl }: Props) => {
  const extension = fileUrl.split('?')[0].split('.').pop()

  if (extension === 'obj') {
    return <OBJModel fileUrl={fileUrl} />
  }

  if (extension === 'stl') {
    return <STLModel fileUrl={fileUrl} />
  }

  return null
}


const ThreeDView = ({ fileUrl }: Props) => (
  <div style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}>
    <Suspense fallback={<Loading />}>
      <Canvas>
        <Stage shadows={false} adjustCamera={1.1}>
          <Float rotationIntensity={1.3}>
            <Model fileUrl={fileUrl} />
          </Float>
        </Stage>
        <OrbitControls />
        <ambientLight />
      </Canvas>
    </Suspense>
  </div>
)

export default ThreeDView