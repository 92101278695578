import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { Button } from '@mui/material'
import { signOut } from 'firebase/auth';

import { auth } from './firebase';
import './App.css';
import { useAuthContext } from './contexts/AuthContext';
import Loading from './components/Loading';
import SignIn from './pages/SignIn';
import ThreeDScans from './pages/ThreeDScans';

function App() {
  const { user, isLoading } = useAuthContext()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {(user && process.env.NODE_ENV === 'development') && (
        <Button
          onClick={() => signOut(auth)}
          sx={{ position: 'absolute', top: '12px', left: '12px', zIndex: 100 }}
          variant="contained"
        >
          ログアウト
        </Button>
      )}
      <Routes>
        {user ? (
          <>
            <Route path="/" element={<ThreeDScans />} />
            <Route path="/sign_in" element={<Navigate replace to="/" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate replace to="/sign_in" />} />
            <Route path="/sign_in" element={<SignIn />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
