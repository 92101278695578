const Empty = () => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%',  }}>
    <img src="metto_icon.png" alt="" style={{ width: '160px' }} />
    <p style={{ color: '#491F00', fontSize: '24px' }}>sorry...</p>
    <p style={{ color: '#491F00', fontSize: '14px', textAlign: 'center', marginTop: '20px' }}>
      表示できる3Dデータがありません。<br />
      <br />
      <br />
      このエラーメッセージが表示された場合は、<br />
      下記よりお問い合わせください。
    </p>
    <a href="https://form.run/@japanmedicalcompany-wSTNAKYUICaNhMyUFNTR" target="_blank" rel="noreferrer" style={{ backgroundColor: '#E9E8E8', borderRadius: '16px', display: 'block', color: '#491F00', fontSize: '16px', lineHeight: 1, marginTop: '20px', padding: '6px 10px', }}>
      お問い合わせ
    </a>
  </div>
)

export default Empty