import { useState, useEffect, ChangeEvent } from 'react'
import { collection, getDocs, Timestamp, query, where } from 'firebase/firestore';
import { User } from 'firebase/auth';
import { sub } from 'date-fns'

import { db } from '../firebase';
import ThreeDScan from '../components/ThreeDScan';
import Slider from '../components/Slider'
import { useAuthContext } from '../contexts/AuthContext';
import Loading from '../components/Loading';
import Empty from '../components/Empty';

type ThreeDScanData = {
  id: string;
  num: number;
  filePath: string;
  scanDate: Timestamp;
}

const ThreeDScans = () => {
  const { user } = useAuthContext()
  const [threeDScans, setThreeDScans] = useState<ThreeDScanData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentScanIndex, setCurrentScanIndex] = useState<number>(0)
  
  useEffect(() => {
    setIsLoading(true)
    const now = new Date()
    console.log(`new Date(): ${now}`)
    const thirtyDaysAgo = sub(now, { days: 30 })
    const collectionRef = collection(db, 'patients', (user as User).uid, 'three_d_scans')
    const q = query(collectionRef, where('scanDate', '<', thirtyDaysAgo));
    getDocs(q)
      .then((snapshot: any) => {
        const newThreeDScans = snapshot.docs.map((doc: any, index: number) => {
          return { id: doc.id, num: index + 1, ...doc.data() }
        })
        console.log(`threeDScans: ${JSON.stringify(newThreeDScans)}`)
        setThreeDScans(newThreeDScans)
        setIsLoading(false)
      })
  }, [user])

  if (isLoading) {
    return <Loading />
  }

  if (threeDScans.length === 0) {
    return <Empty />
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <img src="/metto_logo.png" alt="" style={{ position: 'absolute', top: '-8px', right: '8px', width: '120px', zIndex: 10 }} />

      <ul style={{ flex: 1, position: 'relative' }}>
        {threeDScans.map((s, index) => (
          <li key={s.id} style={{ height: '100%', width: '100%', position: 'absolute', zIndex: index === currentScanIndex ? 1 : 0 }}>
            <ThreeDScan threeDScan={s} />
          </li>
        ))}
      </ul>

      <div style={{ padding: '0 32px' }}>
        <Slider
          value={currentScanIndex}
          onChange={(_: ChangeEvent, value: number) => setCurrentScanIndex(value)}
          marks
          min={0}
          max={threeDScans.length - 1}
        />
      </div>
    </div>
  )
}

export default ThreeDScans