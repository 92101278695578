import { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { onAuthStateChanged, User, signInWithCustomToken } from 'firebase/auth';

import { auth } from '../firebase';


type AuthState = {
  user: User | null;
  isLoading: boolean;
}

const AuthContext = createContext<AuthState>(null!);


type Props = {
  children: ReactElement;
}

export const AuthProvider = ({ children }: Props) => {
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
      const customToken = searchParams.get('token')
      if (user) {
        console.log(`helmetId: ${user.uid}`)
        setCurrentUser(user)
        setIsLoading(false)
      } else {
        if (customToken) {
          signInWithCustomToken(auth, customToken).catch(() => alert('ログインに失敗しました。'))
        } else {
          setCurrentUser(null)
          setIsLoading(false)
        }
      }
    })
    return () => unsubscribe()
  }, [searchParams])

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        isLoading: isLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext);