import { useState } from 'react'
import { signInWithCustomToken } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { TextField, Button } from '@mui/material'

import { auth, functions } from '../firebase';

const getCustomToken = httpsCallable(functions, 'get_custom_token');

const SignIn = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [helmetId, setHelmetId] = useState('')
  const [password, setPassword] = useState('')
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <form
        style={{ maxWidth: '320ox', padding: '16px' }}
        onSubmit={e => {
          e.preventDefault()
          setIsLoading(true)
          getCustomToken({ helmetId, password })
            .then((res: any) => signInWithCustomToken(auth, res.data.token))
            .catch(() => alert('ログインに失敗しました。\n患者IDとパスワードを正しく入力してください。'))
            .finally(() => setIsLoading(false))
        }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          label="ヘルメットID"
          value={helmetId}
          onChange={e => setHelmetId(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="パスワード"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3 }}
          disabled={isLoading}
        >
          ログイン
        </Button>
      </form>
    </div>
  )
}

export default SignIn