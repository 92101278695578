import { useEffect, useState } from 'react'
import { Timestamp } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { format } from 'date-fns';

import ThreeDView from './ThreeDView';
import Loading from './Loading';

const storage = getStorage()

type ThreeDScanData = {
  id: string;
  num: number;
  filePath: string;
  scanDate: Timestamp;
}

type Props = {
  threeDScan: ThreeDScanData
}

const ThreeDScan = ({ threeDScan }: Props) => {
  const [fileUrl, setFileUrl] = useState('')
  useEffect(() => {
    getDownloadURL(ref(storage, threeDScan.filePath)).then(url  => setFileUrl(url))
  }, [threeDScan])

  return (
    <div style={{ backgroundColor: '#fff', height: '100%', width: '100%', position: 'relative' }}>
      {fileUrl ? (
        <ThreeDView fileUrl={fileUrl} />
      ) : (
        <Loading />
      )}
      <p style={{ color: '#333', fontSize: '16px', lineHeight: '1.4', padding: '8px 24px', position: 'absolute', left: 0, bottom: 0 }}>
        {threeDScan.num}回目のスキャン
        <br />
        {format(threeDScan.scanDate.toDate(), 'yyyy/MM/dd')}
      </p>
    </div>
  )
}

export default ThreeDScan